import { Box } from '@mui/material';
import AlertDialog from 'components/molecules/AlertDialog';
import AutoAssignHeader from 'components/organisms/AutoAssignHeader';
import BasicDetails from 'components/organisms/BasicDetails';
import Commodities from 'components/organisms/Commodities';
import DisclosureTnc from 'components/organisms/DisclosureTnc';
import InvestorProfile from 'components/organisms/InvestorProfile';
import Occupation from 'components/organisms/Occupation';
import OcrDetails from 'components/organisms/OcrDetails';
import PersonalData from 'components/organisms/PersonalData';
import UserBanks from 'components/organisms/UserBanks';
import UserWealthTrusted from 'components/organisms/UserWealthTrusted';
import { GOPAY_BANK_ID, NATIONALITIES } from 'config';
import { REMOTE } from 'constants/serverRoutes';
import { useEffect, useState } from 'react';
import { makeRequest } from 'services';
import { useIdleTimer, useLoader, useNotification } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import { OPEN_MODAL } from 'utils/redux/actions';

const AgentPanel = () => {
  const [caseId, setCaseId] = useState(null);
  const [kyc1Data, setKyc1Data] = useState({});
  const [kyc2Data, setKyc2Data] = useState({});
  const [userData, setUserData] = useState({});
  const [ktpOcrData, setKtpOcrData] = useState({});
  const [provinceCollection, setProvinceCollection] = useState([]);
  const [basicKtpDetails, setBasicKtpDetails] = useState({});
  const [mutualFundBankData, setMutualFundBankData] = useState({});
  const [idssBankData, setIdssBankData] = useState({});
  const [otherBanksData, setOtherBanksData] = useState({});
  const [occupationData, setOccupationData] = useState({});
  const [userWealthTrustedData, setUserWealthTrustedData] = useState({});
  const [gssKycData, setGssKycData] = useState({});
  const [commodityData, setCommodityData] = useState({ bankAccountNumber: '', updatedAt: '' });
  const [userAmlRiskRatingData, setUserAmlRiskRatingData] = useState({});
  const [tasksStatus, setTasksStatus] = useState({
    iskyc1Escalated: false,
    isKtpOcrEscalated: false,
    iskyc2Escalated: false,
    isOccupationEscalated: false,
    isUserBanksEscalated: false
  });
  const [, dispatch] = useStateValue();
  const [expired, setExpired] = useState(false);
  const [taskStarted, setTaskStarted] = useState(false);
  const [, setLoader] = useLoader();
  const { showNotification } = useNotification();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (expired) {
      exitCase(caseId);
      setExpired(false);
    }
  }, [expired]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('_expiredTime');
    };
  }, []);

  const exitCase = (caseId) => {
    setLoader(true);
    return makeRequest({
      method: 'PUT',
      url: `${REMOTE.KYC_AUTO}/caseExit/userId/${caseId}`,
      data: {
        isKycAutoAssignPage: true
      }
    })
      .then((result) => {
        if (result.data.data && result.data.data.isKycCaseExit) {
          setCaseId(null);
          timer.cleanUp();
        }
      })
      .finally(() => setLoader(false));
  };

  const onExpired = () => {
    if (localStorage.getItem('_expiredTime')) {
      dispatch({
        type: OPEN_MODAL,
        modalContent: (
          <AlertDialog
            title="Idle Warning"
            alertMessage="You are idle for more than 15 minutes, you will be redirected to home page"
            showCancel={false}
            confirmText="OK"
            onConfirm={() => setExpired(true)}
          />
        )
      });
    }
  };

  const timer = useIdleTimer({
    timeout: 900,
    onExpired: onExpired
  });

  useEffect(() => {
    checkAgentStatus();
  }, []);

  const checkAgentStatus = () => {
    return makeRequest({
      url: `${REMOTE.KYC_AUTO}/agent`
    }).then((result) => {
      if (
        result.data.data &&
        Object.keys(result.data.data).length > 0 &&
        result.data.data.status === 'BUSY'
      ) {
        getCasesId();
      } else {
        timer.cleanUp();
      }
    });
  };

  const getCasesId = (click = false) => {
    setLoading(true);
    return makeRequest({
      method: 'POST',
      url: `${REMOTE.KYC_AUTO}/assignCase`
    })
      .then((result) => {
        if (
          result.data.data &&
          Object.keys(result.data.data).length > 0 &&
          result.data.data.userId
        ) {
          setCaseId(result.data.data.userId);
          result.data.data.userId && fetchCaseDetails(result.data.data.userId);
          timer.startInterval();
        } else {
          if (click) {
            const message = 'No case is available right now, try again later';
            showAlert(message);
          }
        }
      })
      .catch((error) => {
        if (error.code === 400) {
          const message = 'No case is available right now, try again later';
          showAlert(message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showAlert = (message) => {
    dispatch({
      type: OPEN_MODAL,
      modalContent: (
        <AlertDialog title="" alertMessage={message} showCancel={false} confirmText="Ok" />
      )
    });
  };

  const fetchCaseDetails = (caseId) => {
    setLoader(true);
    Promise.allSettled([
      getKyc1Data(caseId),
      getKyc2Data(caseId),
      getUserData(caseId),
      getOccupationData(caseId),
      getMutualFundBankAccount(caseId),
      getIdssBankAccount(caseId),
      getOtherBanksData(caseId),
      getUserWealthTrustedData(caseId),
      getGssKycData(caseId),
      getUserAmlRiskRating(caseId),
      getEscalatedTasks(caseId)
    ]).then(() => {
      setLoader(false);
    });
  };

  const getKyc1Data = (caseId) => {
    let query = {};
    query.userId = caseId;
    query.isKycPage = true;
    return makeRequest({
      url: `${REMOTE.KYC1}`,
      params: query
    }).then((result) => {
      let kyc1 = {};
      let ktpOcr = {};
      if (result.data.content[0] && Object.keys(result.data.content[0]).length > 0) {
        kyc1 = result.data.content[0];
        if (kyc1.idCardPicture) {
          getKtpOcrData(caseId);
          setKyc1Data(kyc1);
        } else {
          ktpOcr.status = 'NOT UPLOADED';
          setKyc1Data(kyc1);
          setKtpOcrData(ktpOcr);
        }
      } else {
        kyc1.status = 'NOT UPLOADED';
        ktpOcr.status = 'NOT UPLOADED';
        setKyc1Data(kyc1);
        setKtpOcrData(ktpOcr);
      }
    });
  };

  const getKtpOcrData = (caseId) => {
    makeRequest({ url: `${REMOTE.KYC1}/ktpOcr/provinces` })
      .then((result) => {
        if (result.data && result.data.data) {
          setProvinceCollection(result.data.data);
        }
      })
      .then(() => {
        return makeRequest({
          url: `${REMOTE.KYC1}/ktpOcr/basicDetails`
        }).then((result) => {
          if (result.data && result.data.data) {
            setBasicKtpDetails(result.data.data);
          }
        });
      })
      .then(() => {
        let query = {};
        query.userId = caseId;
        return makeRequest({
          url: `${REMOTE.KYC1}/ktpOcr`,
          params: query
        }).then((result) => {
          if (result.data) {
            setKtpOcrData(result.data);
          }
        });
      })
      .catch(() => {
        setKtpOcrData({ status: 'NOT UPLOADED' });
      });
  };

  const getKyc2Data = (caseId) => {
    let query = {};
    query.userId = caseId;
    query.showSignature = 1;
    return makeRequest({
      url: `${REMOTE.KYC2}`,
      params: query
    }).then((result) => {
      let kyc2Data = {};
      kyc2Data.status = 'NOT UPLOADED';
      if (result.data.content[0] && Object.keys(result.data.content[0]).length > 0) {
        kyc2Data = result.data.content[0];
      }
      setKyc2Data(kyc2Data);
    });
  };

  const getUserData = (caseId) => {
    makeRequest({
      url: `${REMOTE.USER}/${caseId}`,
      ignoreError: true
    }).then((result) => {
      if (result.data && result.data.content) {
        setUserData(result.data.content[0]);
      }
    });
  };

  const getOccupationData = (caseId) => {
    let query = {};
    query.userId = caseId;
    query.showSignature = 1;
    return makeRequest({
      url: `${REMOTE.OCCUPATION}`,
      params: query
    }).then((result) => {
      let occupationData = {};
      occupationData.status = 'NOT UPLOADED';
      if (result.data && Object.keys(result.data).length > 0) {
        occupationData = result.data;
      }
      setOccupationData(occupationData);
    });
  };

  const getUserAmlRiskRating = (caseId) => {
    setLoader(true);
    makeRequest({
      url: `${REMOTE.USER_AML_RISK_RATING}/byUserId?userId=${caseId}`
    })
      .then((result) => {
        if (result?.data?.data) {
          setUserAmlRiskRatingData(result.data.data);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getMutualFundBankAccount = (caseId) => {
    let query = {};
    query.userId = caseId;
    query.defaultUserBank = true;
    makeRequest({
      url: `${REMOTE.BANK_ACCOUNTS}/filter`,
      params: query
    }).then((result) => {
      let mutualFundBankData = { status: 'NOT UPLOADED' };
      const isKycTaskEscalated = result.data.isKycTaskEscalated;
      if (result.data && result.data.data && result.data.data.length > 0 > 0) {
        mutualFundBankData = result.data.data[0];
      }
      setMutualFundBankData({ ...mutualFundBankData, isKycTaskEscalated });
    });
  };

  const getIdssBankAccount = (caseId) => {
    let query = {};
    query.userId = caseId;
    query.defaultIdssUserBank = true;
    makeRequest({
      url: `${REMOTE.BANK_ACCOUNTS}/filter`,
      params: query
    }).then((result) => {
      let idssBankData = { status: 'NOT UPLOADED' };
      const isKycTaskEscalated = result.data.isKycTaskEscalated;
      if (result.data && result.data.data && result.data.data.length > 0 > 0) {
        idssBankData = result.data.data[0];
      }
      setIdssBankData({ ...idssBankData, isKycTaskEscalated });
    });
  };

  const getOtherBanksData = (caseId) => {
    let query = {};
    query.userId = caseId;
    makeRequest({
      url: `${REMOTE.BANK_ACCOUNTS}/filter`,
      params: query
    }).then((result) => {
      let otherBanksData = { status: 'NOT UPLOADED' };
      const isKycTaskEscalated = result.data.isKycTaskEscalated;
      if (result.data && result.data.data && result.data.data.length > 0 > 0) {
        delete otherBanksData.status;
        otherBanksData.data = result.data.data.filter(
          (bank) =>
            !bank.defaultUserBank &&
            !bank.defaultIdssUserBank &&
            !bank.deleted &&
            bank.bankId !== GOPAY_BANK_ID
        );
        if (otherBanksData.length > 0 === 0) {
          otherBanksData = { status: 'NOT UPLOADED' };
        }
      }
      setOtherBanksData({ ...otherBanksData, isKycTaskEscalated });
    });
  };

  const getUserWealthTrustedData = (caseId) => {
    let query = {};
    query.userId = caseId;
    return makeRequest({
      url: `${REMOTE.USER_WEALTH_TRUSTED_CONTACTS}`,
      params: query
    }).then((result) => {
      let userWealthTrustedData = {};
      userWealthTrustedData.status = 'NOT UPLOADED';
      if (result.data.content[0] && Object.keys(result.data.content[0]).length > 0) {
        userWealthTrustedData = result.data.content[0];
      }
      setUserWealthTrustedData(userWealthTrustedData);
    });
  };

  const getGssKycData = (caseId) => {
    let query = {};
    query.userId = caseId;
    return makeRequest({
      url: `${REMOTE.USER_GSS_KYC_INFORMATION}`,
      params: query
    }).then((result) => {
      let gssKycData = {};
      gssKycData.status = 'NOT UPLOADED';
      if (result.data.content[0] && Object.keys(result.data.content[0]).length > 0) {
        gssKycData = result.data.content[0];
        if (result.data.content[0].status === 'VERIFIED') {
          getCommodityData(caseId);
        }
      }
      setGssKycData(gssKycData);
    });
  };

  const getCommodityData = (caseId) => {
    return makeRequest({
      url: `${REMOTE.BAG_USER_BANK_ACCOUNTS}/user/${caseId}`
    }).then((result) => {
      let commodityData = {};
      if (result.data?.data?.data?.length > 0) {
        commodityData.bankAccountNumber = result.data.data.data[0].bankAccountNumber;
        commodityData.updatedAt = result.data.data.data[0].updatedAt;
        setCommodityData(commodityData);
      }
    });
  };

  const getEscalatedTasks = (caseId) => {
    let query = {};
    query.userId = caseId;
    makeRequest({
      url: `${REMOTE.KYC_AUTO}/getAllEscalatedTask`,
      params: query
    }).then((result) => {
      if (result.data.data) {
        const {
          BANK_ACCOUNT,
          BASIC_VERIFICATION,
          OCCUPATION,
          OCR_VERIFICATION,
          PERSONAL_VERIFICATION
        } = result.data.data;
        setTasksStatus({
          iskyc1Escalated: BASIC_VERIFICATION,
          isKtpOcrEscalated: OCR_VERIFICATION,
          iskyc2Escalated: PERSONAL_VERIFICATION,
          isOccupationEscalated: OCCUPATION,
          isUserBanksEscalated: BANK_ACCOUNT
        });
      }
    });
  };

  const escalateTask = (taskType, callback) => {
    setLoader(true);
    return makeRequest({
      method: 'PUT',
      url: `${REMOTE.KYC_AUTO}/taskEscalate/userId/${caseId}/taskType/${taskType}`,
      data: {
        isKycAutoAssignPage: true
      }
    })
      .then(() => {
        timer.cleanUp();
        timer.startInterval();
        setTaskStarted(false);
        callback();
        showNotification('success', 'Task escalated');
      })
      .finally(() => setLoader(false));
  };

  const startTask = (taskType) => {
    if (!taskStarted) {
      setLoader(true);
      return makeRequest({
        method: 'PUT',
        url: `${REMOTE.KYC_AUTO}/startTask/userId/${caseId}/type/${taskType}`,
        data: {
          isKycAutoAssignPage: true
        }
      })
        .then(() => {
          setTaskStarted(true);
          timer.cleanUp();
          timer.startInterval();
        })
        .finally(() => setLoader(false));
    }
  };

  const resetState = () => {
    setCaseId(null);
    setKyc1Data({});
    setKyc2Data({});
    setUserData({});
    setKtpOcrData({});
    setProvinceCollection({});
    setBasicKtpDetails({});
    setMutualFundBankData({});
    setIdssBankData({});
    setOtherBanksData({});
    setOccupationData({});
    setUserWealthTrustedData({});
    setGssKycData({});
    setTasksStatus({
      iskyc1Escalated: false,
      isKtpOcrEscalated: false,
      iskyc2Escalated: false,
      isOccupationEscalated: false,
      isUserBanksEscalated: false
    });
  };

  return (
    <>
      <AutoAssignHeader
        caseId={caseId}
        getCasesId={getCasesId}
        timer={timer}
        loading={loading}
        exitCase={() => exitCase(caseId)}
        resetState={resetState}
      />
      {caseId && (
        <Box sx={{ mt: 3 }}>
          {Object.keys(kyc1Data).length > 0 &&
            Object.keys(ktpOcrData).length > 0 &&
            kyc1Data.nationality === NATIONALITIES.INDONESIA && (
              <OcrDetails
                startTask={(type) => startTask(type)}
                startInterval={() => timer.startInterval()}
                endTask={() => {
                  setTaskStarted(false);
                  timer.cleanUp();
                }}
                escalateTask={(type, callback) => {
                  escalateTask(type, callback);
                }}
                ktpOcrData={ktpOcrData}
                escalate={tasksStatus}
                kyc1Data={kyc1Data}
                basicKtpDetails={basicKtpDetails}
                provinceCollection={provinceCollection}
                setKtpOcrData={setKtpOcrData}
                setEscalate={setTasksStatus}
              />
            )}
          {Object.keys(kyc1Data).length > 0 &&
            Object.keys(userData).length > 0 &&
            Object.keys(ktpOcrData).length > 0 && (
              <BasicDetails
                startTask={(type) => startTask(type)}
                startInterval={() => timer.startInterval()}
                endTask={() => {
                  setTaskStarted(false);
                  timer.cleanUp();
                }}
                escalateTask={(type, callback) => {
                  escalateTask(type, callback);
                }}
                getKyc1Data={() => {
                  getKyc1Data(caseId);
                }}
                userAmlRiskRatingData={userAmlRiskRatingData}
                ktpOcrData={ktpOcrData}
                escalate={tasksStatus}
                kyc1Data={kyc1Data}
                userData={userData}
                setEscalate={setTasksStatus}
                setKyc1Data={setKyc1Data}
              />
            )}
          {Object.keys(kyc2Data).length > 0 && Object.keys(kyc1Data).length > 0 && (
            <PersonalData
              startTask={(type) => startTask(type)}
              startInterval={() => timer.startInterval()}
              endTask={() => {
                setTaskStarted(false);
                timer.cleanUp();
              }}
              escalateTask={(type, callback) => {
                escalateTask(type, callback);
              }}
              ktpOcrData={ktpOcrData}
              escalate={tasksStatus}
              kyc2Data={kyc2Data}
              kyc1Data={kyc1Data}
              setKyc2Data={setKyc2Data}
              setEscalate={setTasksStatus}
            />
          )}
          {Object.keys(occupationData).length > 0 && (
            <Occupation
              startTask={(type) => startTask(type)}
              startInterval={() => timer.startInterval()}
              endTask={() => {
                setTaskStarted(false);
                timer.cleanUp();
              }}
              escalateTask={(type, callback) => {
                escalateTask(type, callback);
              }}
              escalate={tasksStatus}
              ktpOcrData={ktpOcrData}
              kyc2Data={kyc2Data}
              kyc1Data={kyc1Data}
              occupationData={occupationData}
              setOccupationData={setOccupationData}
              setEscalate={setTasksStatus}
            />
          )}
          {Object.keys(mutualFundBankData).length > 0 &&
            Object.keys(idssBankData).length > 0 &&
            Object.keys(otherBanksData).length > 0 && (
              <UserBanks
                startTask={(type) => startTask(type)}
                startInterval={() => timer.startInterval()}
                endTask={() => {
                  setTaskStarted(false);
                  timer.cleanUp();
                }}
                escalateTask={(type, callback) => {
                  escalateTask(type, callback);
                }}
                escalate={tasksStatus}
                mutualFundBankData={mutualFundBankData}
                idssBankData={idssBankData}
                otherBanksData={otherBanksData}
                setEscalate={setTasksStatus}
                caseId={caseId}
              />
            )}
          {Object.keys(userData).length > 0 && <InvestorProfile userData={userData} />}
          {Object.keys(kyc2Data).length > 0 && Object.keys(kyc1Data).length > 0 && (
            <UserWealthTrusted
              userWealthTrustedData={userWealthTrustedData}
              kyc2Data={kyc2Data}
              kyc1Data={kyc1Data}
            />
          )}
          {Object.keys(gssKycData).length > 0 && <DisclosureTnc gssKycData={gssKycData} />}
          {Object.keys(commodityData).length > 0 && <Commodities commodityData={commodityData} />}
        </Box>
      )}
    </>
  );
};

export default AgentPanel;
