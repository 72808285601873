import { Box, Button, Dialog, DialogActions, Link } from '@mui/material';
import CsvFilePreview from 'components/molecules/CsvFilePreview';
import UploadFile from 'components/molecules/UploadFile';
import PluangListPage from 'components/organisms/PluangListPage';
import { REMOTE } from 'constants/serverRoutes';
import parseCSV from 'helpers/csvToJson';
import { useState } from 'react';
import { makeRequest } from 'services';
import { useLoader, useNotification } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import {
  APPROVAL_STATUS,
  BAG_MANUAL_TRANSFERS_ACCESS_ROLES,
  BAG_MANUAL_TRANSFERS_TRANSACTION_TYPE,
  TRANSFER_STATUS
} from '../constants';

const filters = [
  {
    id: 'pcaAccountNumber',
    type: 'text',
    label: 'PCA Account Number'
  },
  {
    id: 'status',
    type: 'dropdown',
    label: 'Approval Status',
    default: '',
    options: [
      { text: 'All', value: '' },
      { text: 'Approved', value: APPROVAL_STATUS.APPROVED.toLowerCase() },
      { text: 'Pending', value: APPROVAL_STATUS.PENDING.toLowerCase() },
      { text: 'Rejected', value: APPROVAL_STATUS.REJECTED.toLowerCase() }
    ]
  },
  {
    id: 'transferStatus',
    type: 'dropdown',
    label: 'Transfer Status',
    default: '',
    options: [
      { text: 'All', value: '' },
      { text: 'Completed', value: TRANSFER_STATUS.COMPLETED.toLowerCase() },
      { text: 'Expired', value: TRANSFER_STATUS.EXPIRED.toLowerCase() },
      { text: 'Failed', value: TRANSFER_STATUS.FAILED.toLowerCase() },
      { text: 'Pending', value: TRANSFER_STATUS.PENDING.toLowerCase() }
    ]
  },
  {
    id: 'transactionType',
    type: 'dropdown',
    label: 'Transaction Type',
    default: '',
    options: [
      { text: 'All', value: '' },
      { text: 'PCA_PGB', value: BAG_MANUAL_TRANSFERS_TRANSACTION_TYPE.PCA_PGB.toLowerCase() },
      { text: 'PCA_PES', value: BAG_MANUAL_TRANSFERS_TRANSACTION_TYPE.PCA_PES.toLowerCase() },
      { text: 'PES_PGB', value: BAG_MANUAL_TRANSFERS_TRANSACTION_TYPE.PES_PGB.toLowerCase() },
      { text: 'PGB_PES', value: BAG_MANUAL_TRANSFERS_TRANSACTION_TYPE.PGB_PES.toLowerCase() }
    ]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  },
  {
    type: 'exportExcel'
  }
];

const headers = [
  { header_name: 'Reference Id', db_column_name: 'refId' },
  { header_name: 'PCA Account', db_column_name: 'pcaAccountNumber' },
  { header_name: 'Transaction Type', db_column_name: 'transactionType', formatAsStatus: 'false' },
  { header_name: 'Amount', db_column_name: 'amount', formattedCurrency: true },
  { header_name: 'Approval Status', db_column_name: 'status' },
  { header_name: 'Transfer Status', db_column_name: 'transferStatus' },
  { header_name: 'Created', db_column_name: 'created' }
];

const transferTemplateUrl =
  'https://pluang-production-uploads.s3.ap-southeast-1.amazonaws.com/bag_manual_transfers_uploads/bag_upload_transfer_template.csv';
const approvalTemplateUrl =
  'https://pluang-production-uploads.s3.ap-southeast-1.amazonaws.com/bag_manual_transfers_uploads/bag_upload_approval_template.csv';
const balnceRequestTemplateUrl =
  'https://pluang-production-uploads.s3.ap-southeast-1.amazonaws.com/bag_manual_transfers_uploads/pca_balance_request_template.csv';

export const uploadTransferHeaders = ['refId', 'pcaAccountNumber', 'transactionType', 'amount'];
export const uploadApprovalHeaders = ['refId', 'status', 'rejectionReason'];
export const uploadBalanceRequestHeaders = ['AccountNo'];

const BagManualTransfersList = () => {
  const [, setLoader] = useLoader();
  const { showNotification } = useNotification();
  const [appState] = useStateValue();
  const [uploadType, setUploadType] = useState(null);
  const [uploadData, setUploadData] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const userRoles = appState.user?.roles;
  const isRoleVerified =
    userRoles?.includes(BAG_MANUAL_TRANSFERS_ACCESS_ROLES.SUPER_ADMIN) ||
    userRoles?.includes(BAG_MANUAL_TRANSFERS_ACCESS_ROLES.FINANCE_MANAGER);

  const previewUpload = (event, uploadType, csvHeaders) => {
    if (!event?.target?.files?.length) return;

    setLoader(true);
    const file = event.target.files[0];
    event.target.value = null;
    parseCSV(file, uploadType)
      .then((result) => {
        if (result.length > 100) {
          showNotification('error', 'Records should not be more than 100');
          return;
        }
        setIsPreviewOpen(true);
        setUploadType(uploadType);
        setCsvHeaders(csvHeaders);
        setUploadData(result);
        setUploadedFile(file);
        showNotification('success', 'Click Submit to upload', 'File parsed successfully');
      })
      .catch((err) => {
        clearUploadData();
        showNotification('error', err?.error_message || err?.msg || err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleUpload = () => {
    let data = new FormData();
    data.append('file', uploadedFile);
    data.append('uploadType', uploadType);
    setLoader(true);
    makeRequest({
      url: `${REMOTE.BAG_MANUAL_TRANSFERS}/upload`,
      data,
      method: 'POST'
    })
      .then((result) => {
        if (result.data?.success) {
          showNotification('success', 'File uploaded successfully');
        }
      })
      .finally(() => {
        setLoader(false);
        closePreview();
      });
  };

  const clearUploadData = () => {
    setUploadData([]);
    setUploadedFile(null);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    clearUploadData();
  };

  const headerChild = () => {
    return (
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 4 }}>
          <Box>
            <Link href={transferTemplateUrl} underline="hover">
              Upload Transfer/
            </Link>
            <Link href={approvalTemplateUrl} underline="hover">
              Approval Template
            </Link>
          </Box>
          <Link href={balnceRequestTemplateUrl} underline="hover">
            Upload Balance Request Template
          </Link>
        </Box>
        <UploadFile
          onChange={(e) => previewUpload(e, 'uploadTransfer', uploadTransferHeaders)}
          uploadBtnText="Upload Transfer"
          color="info"
        />
        {isRoleVerified && (
          <UploadFile
            onChange={(e) => previewUpload(e, 'uploadApproval', uploadApprovalHeaders)}
            uploadBtnText="Upload Approval"
          />
        )}
        <UploadFile
          onChange={(e) => previewUpload(e, 'uploadBalanceRequest', uploadBalanceRequestHeaders)}
          uploadBtnText="Upload Balance Request"
        />
      </Box>
    );
  };

  return (
    <>
      <PluangListPage
        title="BAG Manual Transfer"
        filters={filters}
        headers={headers}
        headerChild={headerChild}
        resource="BAG_MANUAL_TRANSFERS"
      />
      <Dialog maxWidth="md" fullWidth open={isPreviewOpen} onClose={closePreview}>
        <CsvFilePreview headers={csvHeaders} uploadData={uploadData} />
        <DialogActions sx={{ mt: 0.5 }}>
          <Button color="success" size="small" variant="contained" onClick={handleUpload}>
            Save
          </Button>
          <Button sx={{ ml: 2 }} size="small" variant="outlined" onClick={closePreview}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BagManualTransfersList;
