import { Box, Button, Dialog, Grid, TextField, Typography } from '@mui/material';
import AlertDialog from 'components/molecules/AlertDialog';
import BasicTable from 'components/molecules/BasicTable';
import EditDialog from 'components/molecules/EditDialog';
import ImageMagnifier from 'components/molecules/ImageMagnifier';
import KycTask from 'components/molecules/KycTask';
import ListDialog from 'components/molecules/ListDialog';
import RejectionReasonContent from 'components/molecules/RejectionReason';
import S3DownloadLink from 'components/molecules/s3DownloadLink';
import {
  occupationRejectionOptionsInEn,
  occupationRejectionOptionsInId
} from 'constants/rejectionOptions';
import { REMOTE } from 'constants/serverRoutes';
import { dateFormatter } from 'helpers/dateChanger';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { makeRequest } from 'services';
import { useKyc, useLoader, useNotification } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import { OPEN_MODAL } from 'utils/redux/actions';
const npWpRegex = new RegExp(/^[0-9]{15}$/);
const listHeaders = ['createdAt', 'fromState', 'toState', 'rejectionReason', 'rejectionReasonId'];

const Occupation = ({
  startTask,
  startInterval,
  endTask,
  escalateTask,
  escalate,
  kyc2Data,
  kyc1Data,
  occupationData,
  ktpOcrData: ktpOcr,
  setEscalate,
  setOccupationData,
  isAutoAssign = true
}) => {
  const [, setLoader] = useLoader();
  const [occupationState, setOccupationState] = useState({
    rejectionReasonLogData: [],
    npwpNumber: '',
    deg: 0,
    scale: 1,
    flip: 1,
    rejectionCount: 0,
    englishOptions: [],
    bahasaOptions: []
  });
  const [, dispatch] = useStateValue();
  const [showModal, setShowModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showRejectionModal, setRejectionModal] = useState(false);
  const { showNotification } = useNotification();
  const { getRejectionJson, generateReason } = useKyc();

  useEffect(() => {
    setOccupationState((prevState) => ({
      ...prevState,
      npwpNumber: occupationData.npwpNumber
    }));
  }, [occupationData.npwpNumber]);

  const handleDropdown = (action) => {
    setShowDetail(!action);
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    setOccupationState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const getRejectionReasonData = (userId) => {
    setLoader(true);
    return makeRequest({
      url: `${REMOTE.OCCUPATION}/stateTransition/`,
      params: {
        userId,
        toState: 'REJECTED'
      }
    })
      .then((result) => result)
      .finally(() => setLoader(false));
  };

  const getRejectionReasonLog = () => {
    if (!occupationState.rejectionReasonLogData.length > 0) {
      getRejectionReasonData(occupationData.userId).then((result) => {
        if (result.data && result.data.data && result.data.data.length > 0) {
          setOccupationState((prevState) => ({
            ...prevState,
            rejectionCount: result.data.data.length,
            rejectionReasonLogData: result.data.data
          }));
          openRejectionReasonDialog(result.data.data);
        } else {
          showNotification('error', 'No rejection reason logs found');
        }
      });
    } else openRejectionReasonDialog(occupationState.rejectionReasonLogData);
  };

  const openRejectionReasonDialog = (data) => {
    dispatch({
      type: OPEN_MODAL,
      modalContent: <ListDialog title="Rejection Reason logs" data={data} headers={listHeaders} />
    });
  };

  const getRejectionReasonInIndonesianLang = (rejectionReasonJson) => {
    const rejectionData = rejectionReasonJson && rejectionReasonJson.id;
    const rejectionReason = [];
    if (Array.isArray(rejectionData)) {
      rejectionData.forEach((reason) => {
        rejectionReason.push(reason.value);
      });
    } else {
      return rejectionData;
    }
    return rejectionReason.join(',');
  };

  const updatedStatus = (occupationStatus) => {
    let reasonEn = generateReason('en', occupationState);
    let reasonIn = generateReason('in', occupationState);
    const rejectionReasonJson = getRejectionJson(occupationState);

    if (occupationStatus === 'REJECTED' && !reasonEn && !reasonIn) {
      showNotification('error', 'Rejection reason is required');
      return;
    }
    let data = {
      ...occupationData,
      rejectionReason: rejectionReasonJson,
      status: occupationStatus
    };
    if (isAutoAssign) {
      data['isKycAutoAssignPage'] = true;
    }
    setLoader(true);
    makeRequest({
      url: `${REMOTE.OCCUPATION}/updateStatus`,
      method: 'PUT',
      data
    })
      .then((result) => {
        if (result.data && result.data.success) {
          showNotification('success', 'Status updated');
          setOccupationData((prevState) => ({
            ...prevState,
            status: occupationStatus
          }));
          endTask?.();
          startInterval?.();
        }
      })
      .finally(() => setLoader(false));
  };

  const updateNpwpNumber = () => {
    if (
      (occupationState.npwpNumber && !npWpRegex.test(occupationState.npwpNumber)) ||
      !occupationState.npwpNumber
    ) {
      showNotification('error', 'Invalid Npwp Number');
      return;
    }
    let data = {
      ...occupationData,
      npwpNumber: occupationState.npwpNumber
    };
    if (isAutoAssign) {
      data['isKycAutoAssignPage'] = true;
    }
    setLoader(true);
    makeRequest({
      url: `${REMOTE.OCCUPATION}/updateStatus`,
      method: 'PUT',
      data
    })
      .then((result) => {
        if (result.data && result.data.success) {
          showNotification('success', 'Npwp Number Updated');
          setOccupationData((prevState) => ({
            ...prevState,
            occupationData: { ...occupationData, npwpNumber: occupationState.npwpNumber }
          }));
          setShowModal(false);
        }
      })
      .finally(() => setLoader(false));
  };

  const escalateOccupationTask = () => {
    escalateTask('OCCUPATION', () => {
      setEscalate((prevState) => ({
        ...prevState,
        isOccupationEscalated: true
      }));
    });
  };

  const closeRejectionDialog = () => {
    setRejectionModal(false);
    setOccupationState((prevState) => ({
      ...prevState,
      englishOptions: [],
      bahasaOptions: []
    }));
  };

  const occupationTableData = [
    { label: 'Position', value: occupationData.position },
    { label: 'Company name', value: occupationData.companyName },
    { label: 'Company Country', value: occupationData.companyCountry },
    { label: 'Company Address', value: occupationData.companyAddress },
    { label: 'Company Province', value: occupationData.companyProvince },
    { label: 'Company City', value: occupationData.companyCity },
    { label: 'Company District', value: occupationData.companyDistrict },
    { label: 'Company Sub District', value: occupationData.companySubDistrict },
    { label: 'Business Nature', value: occupationData.businessNature },
    { label: 'Company Postal Code', value: occupationData.companyPostalCode },
    {
      label: 'Rejection Reasons',
      value: occupationData.rejectionReason
        ? getRejectionReasonInIndonesianLang(occupationData.rejectionReason)
        : ''
    },
    {
      label: 'npwp Number',
      value:
        !occupationData.signedNpwpPicture && !occupationData.npwpNumber ? (
          `User doesn't have NPWP, please check consent letter below`
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {occupationData.npwpNumber}
            <Button
              size="small"
              variant="outlined"
              onClick={() => setShowModal(true)}
              disabled={escalate.isOccupationEscalated && isAutoAssign}>
              Edit
            </Button>
          </Box>
        )
    },
    { label: 'Autoverified', value: String((occupationData && occupationData.autoVerified) || '') },
    {
      label: 'Consent Letter',
      value: occupationData.signedConsentLetter ? (
        <S3DownloadLink url={occupationData.signedConsentLetter} />
      ) : occupationData.npwpNumber || occupationData.signedNpwpPicture ? (
        ''
      ) : (
        'Waiting for NPWP to be uploaded, proceed to approve'
      )
    },
    {
      label: 'Last Upload Time',
      value: occupationData.lastUploadTime ? dateFormatter(occupationData.lastUploadTime) : ''
    },
    {
      label: 'Last Update Time',
      value: occupationData.updatedAt ? dateFormatter(occupationData.updatedAt) : ''
    }
  ];

  const shouldEnable =
    isAutoAssign &&
    ((kyc1Data.status === 'UPLOADED' &&
      !escalate.iskyc1Escalated &&
      kyc1Data.dualApprovalStatus !== 'NEED_APPROVAL') ||
      (kyc2Data && !escalate.iskyc2Escalated && kyc2Data.personalDataStatus === 'PENDING'));

  const kycTaskStatus = () => (
    <>
      {escalate && escalate.isOccupationEscalated && (
        <Typography sx={{ border: 1, color: 'red', mr: 2, p: '2px' }}>ESCALATED</Typography>
      )}
      {occupationData.status === 'VERIFIED' ? (
        <Typography sx={{ color: 'green' }}>{occupationData.status}</Typography>
      ) : (
        <Typography>{occupationData.status}</Typography>
      )}
    </>
  );

  const handleKycTaskClick = () => {
    if (occupationData.status === 'UPLOADED' && !escalate.isOccupationEscalated && isAutoAssign) {
      startTask('OCCUPATION');
    }
    handleDropdown(showDetail);
  };

  const isTaskDisabled =
    occupationData.status === 'NOT UPLOADED' ||
    (shouldEnable && occupationData.status === 'UPLOADED');

  return (
    <KycTask
      title="Occupation"
      status={kycTaskStatus()}
      onClick={handleKycTaskClick}
      disabled={isTaskDisabled}
      open={showDetail}>
      <BasicTable tableData={occupationTableData} />
      <Box my={2} display="flex" justifyContent="center">
        {occupationData.status !== 'VERIFIED' && (
          <Button
            sx={{ mr: 2 }}
            color="success"
            size="small"
            variant="contained"
            onClick={() =>
              dispatch({
                type: OPEN_MODAL,
                modalContent: (
                  <AlertDialog
                    title="Verify Occupation Data"
                    alertMessage="Are you sure want to verify?"
                    onConfirm={() => updatedStatus('VERIFIED')}
                  />
                )
              })
            }
            disabled={escalate.isOccupationEscalated && isAutoAssign}>
            Approve
          </Button>
        )}
        <Button
          sx={{ mr: 2 }}
          color="error"
          size="small"
          variant="contained"
          onClick={() => setRejectionModal(true)}
          disabled={escalate.isOccupationEscalated && isAutoAssign}>
          Reject
        </Button>
        <Button sx={{ mr: 2 }} size="small" variant="contained" onClick={getRejectionReasonLog}>
          Rejection reason logs
        </Button>
        {occupationData.status === 'UPLOADED' && isAutoAssign && (
          <Button
            size="small"
            variant="outlined"
            onClick={() =>
              dispatch({
                type: OPEN_MODAL,
                modalContent: (
                  <AlertDialog
                    title="Escalate Occupation Data"
                    alertMessage="Are you sure want to escalate?"
                    onConfirm={escalateOccupationTask}
                  />
                )
              })
            }
            disabled={escalate.isOccupationEscalated}>
            {escalate.isOccupationEscalated ? 'Escalated' : 'Escalate'}
          </Button>
        )}
      </Box>
      <EditDialog
        showModal={showModal}
        setShowModal={setShowModal}
        title="Edit NPWP Number"
        idCardPicture={occupationData.signedNpwpPicture}
        handleSubmit={updateNpwpNumber}>
        <TextField
          fullWidth
          size="small"
          type="text"
          label="NPWP number"
          value={occupationState.npwpNumber}
          name={'npwpNumber'}
          onChange={(e) => handleChange(e)}
        />
      </EditDialog>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: '800px',
              maxWidth: '800px'
            }
          }
        }}
        open={showRejectionModal}
        onClose={closeRejectionDialog}>
        <AlertDialog
          title="Reject Occupation Data"
          alertMessage="Reason?"
          onClose={closeRejectionDialog}
          onConfirm={() => updatedStatus('REJECTED')}>
          <RejectionReasonContent
            englishOptions={occupationState.englishOptions}
            bahasaOptions={occupationState.bahasaOptions}
            rejectionOptionsInEn={occupationRejectionOptionsInEn}
            rejectionOptionsInId={occupationRejectionOptionsInId}
            setRejectionReasonState={setOccupationState}
          />
        </AlertDialog>
      </Dialog>
      {occupationData.id > 0 ? (
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6">npwp picture</Typography>
            {occupationData.signedNpwpPicture && (
              <ImageMagnifier url={occupationData.signedNpwpPicture} />
            )}
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ color: 'red', textAlign: 'center', py: 2 }}>No Data Found</Box>
      )}
    </KycTask>
  );
};

Occupation.propTypes = {
  startTask: PropTypes.func,
  startInterval: PropTypes.func,
  endTask: PropTypes.func,
  escalateTask: PropTypes.func,
  escalate: PropTypes.object,
  kyc2Data: PropTypes.object,
  ktpOcrData: PropTypes.object,
  kyc1Data: PropTypes.object,
  occupationData: PropTypes.object,
  setEscalate: PropTypes.func,
  setOccupationData: PropTypes.func,
  isAutoAssign: PropTypes.bool
};

export default Occupation;
