import {
  APPROVAL_STATUS,
  BAG_MANUAL_TRANSFERS_TRANSACTION_TYPE
} from 'containers/fi/bagManualTransfers/constants';
import Joi from 'joi';

export const uploadTransferSchema = Joi.object({
  refId: Joi.string().required(),
  pcaAccountNumber: Joi.string().required(),
  transactionType: Joi.string()
    .valid(...Object.values(BAG_MANUAL_TRANSFERS_TRANSACTION_TYPE))
    .required(),
  amount: Joi.number().positive().required()
}).required();

export const uploadApprovalSchema = Joi.object({
  refId: Joi.string().required(),
  status: Joi.string()
    .valid(...Object.values(APPROVAL_STATUS))
    .required(),
  rejectionReason: Joi.when('status', {
    is: APPROVAL_STATUS.REJECTED,
    then: Joi.string().required(),
    otherwise: Joi.string().allow(null, '')
  })
}).required();

export const uploadBalanceRequestSchema = Joi.object({
  AccountNo: Joi.string().required()
}).required();
