export const REMOTE = {
  ACCOUNT: '/admin/account',
  ADMIN: '/admin',
  ADMIN_LOGIN: '/admin/auth/login',
  ADMIN_FORGOT: '/admin/auth/forgot',
  ADMIN_RESET: '/admin/auth/reset',
  ADMIN_LOGOUT: '/admin/auth/logout',
  ADMIN_ROLES: '/admin/roles',
  ADMIN_RESOURCES: '/admin/resources',
  API_KEYS: '/admin/:partnerId/apiKeys',
  APP_SETTINGS: '/admin/appSettings',
  AUTO_INVEST: '/admin/autoInvest',
  BAG_KYC: '/admin/bagKyc',
  BAG_MANUAL_TRANSFERS: '/admin/bagManualTransfers',
  BAG_USER_BANK_ACCOUNTS: '/admin/bagUserBankAccounts',
  BANK_ACCOUNTS: '/admin/bankAccount',
  BCA_CREDIT_NOTIFICATIONS: '/admin/bcaCreditNotifications',
  BCA_ACCOUNT_STATEMENT: '/admin/bcaAccountStatement',
  BCA_TOPUP: '/admin/topup/bca',
  CASHOUT: '/admin/cashout',
  CASHOUT_CONFIGURATION: '/admin/cashoutConfiguration',
  COMMISSIONS: '/admin/commission',
  COMMUNITY_CODE: '/admin/communityCode',
  COMPETITOR: '/admin/competitor',
  COMPETITOR_PRICE: '/admin/competitorPrice',
  CRYPTO_ACCOUNT: '/admin/cryptoAccount',
  CRYPTO_CONFIGURATION: '/admin/cryptocurrencyConfiguration',
  CRYPTO_CURRENCY: '/admin/cryptoCurrency',
  CRYPTO_CURRENCY_PARTNER_SPREADS: '/admin/cryptoCurrencyPartnerSpreads',
  CRYPTO_CURRENCY_CURRENT_PRICE: '/admin/cryptocurrencyPrice/currentPrice',
  CRYPTO_CUSTODIAN: '/admin/cryptocurrencyCustodianAsset',
  CRYPTO_SAFETY_LABELS: '/admin/cryptoCurrencySafetyLabels',
  CRYPTO_FUTURES: '/admin/cryptoFutures',
  CRYPTO_FUTURE_FUNDING: '/admin/cryptoFutureFunding',
  CRYPTO_FUTURE_TRANSACTIONS: '/admin/cryptoFutureTransactions',
  CRYPTO_FUTURE_TRANSFER: '/admin/cryptoFutureTransfers',
  CRYPTO_TRANSACTIONS: '/admin/cryptoTransactions',
  CRYPTO_TRANSFER: '/admin/cryptocurrencyTransfer',
  CRYPTO_TRANSFER_RULE: '/admin/cryptocurrencyTransferRule',
  CRYPTO_TRAVEL_RULE_ADDRESS: '/admin/cryptocurrencyTravelRuleAddress',
  DISTRICT: '/admin/district',
  EDD: '/admin/edd',
  FILE_DOWNLOAD: '/admin/download',
  FOREX: '/admin/forex',
  FUND: '/admin/funds',
  FUND_AUM: '/admin/fundAum',
  FUND_BANK_TRANSACTIONS: '/admin/fundBankTransactions',
  FUND_DIVIDEND: '/admin/fundDividend',
  FUND_PRICE: '/admin/fundPrice',
  FUND_SWITCH_TRANSACTIONS: '/admin/fundSwitchTransactions',
  FUND_TRANSACTIONS: '/admin/fundTransactions',
  GLOBAL_SINGLE_STOCK: '/admin/globalSingleStock',
  GLOBAL_SINGLE_STOCK_PARTNER_SPREAD: '/admin/globalSingleStockPartnerSpread',
  GLOBAL_SINGLE_STOCK_TRANSACTIONS: '/admin/globalSingleStockTransactions',
  GLOBAL_TRANSACTIONS: '/admin/globalTransactions',
  GOLD_ACCOUNT: '/admin/goldAccount',
  GOLD_PRICE: '/admin/goldPrice',
  GOLD_PRINTING_FEE: '/admin/goldPrintingFee',
  GOLD_SEND_TRANSACTION: '/admin/goldSendTransaction',
  GOLD_WITHDRAWAL: '/admin/withdrawal',
  IDSS: '/admin/idss',
  IDSS_EIPO: '/admin/idss/eipo',
  IDSS_TRANSACTIONS: '/admin/idssTransactions',
  INBOX_CARD: '/admin/inboxCard',
  INDO_STOCK_USER: '/admin/indoStockUser',
  INFLUENCER: '/admin/influencer',
  INVALID_VA_PAYMENT: '/admin/invalidVaPayment',
  KTP: '/admin/ktp',
  KYC1: '/admin/kyc1',
  KYC2: '/admin/kyc2',
  KYC_AUTO: '/admin/kycAuto',
  KYC_PDF: '/admin/kycPdf',
  LOANS: '/admin/installments/loans',
  LOAN_CALCULATE: '/admin/installments/calculate',
  LOANS_DEFAULTER: '/admin/installments/defaultPayments',
  LOAN_PAYMENTS: '/admin/installments/payments',
  LOAN_STATUS: '/admin/installments/loans/:loanId/:status',
  MARKET_ALERTS: '/admin/marketAlert',
  ME: '/admin/auth/me',
  MERCHANT: '/admin/merchant',
  MISSION: '/admin/mission',
  MISSION_PARTNER: '/admin/missionPartner',
  MISSION_REWARD_CLAIM: '/admin/mission/rewardClaim',
  MISSION_USER_ACTION: '/admin/mission/userAction',
  MISSION_USER_ACTIONS_ARCHIVE: '/admin/mission/userActionsArchive',
  OCCUPATION: '/admin/occupation',
  OPTIONS_TRANSACTIONS: '/admin/optionsTransactions',
  POCKETS: '/admin/pockets',
  PARTNER: '/admin/partner',
  POID_VOUCHERS_DEACTIVATE: '/admin/voucher/purchaseOrder/:orderId/deactivate',
  POID_VOUCHERS_ACTIVATE: '/admin/voucher/purchaseOrder/:orderId/activate',
  PROMOTION: '/admin/appSettings/promotion',
  PROMO_CODE: '/admin/promoCode',
  PURCHASE_ORDER: 'admin/voucher/purchaseOrder',
  PURCHASE_ORDER_MERCHANT: 'admin/purchaseOrder/createPurchaseOrder',
  RDN_CASHOUT: '/admin/rdnCashout',
  REFERRAL: '/admin/referral',
  SECTION: '/admin/section',
  S_INVEST_REPORT: '/admin/sInvestReport',
  S_INVEST_SWITCHING_TRANSACTION: '/admin/sInvestSwitchingTransaction',
  S_INVEST_TRANSACTION: '/admin/sInvestTransaction',
  STOCK_DIVIDENDS: '/admin/stockDividends',
  STOCK_SPLIT: '/admin/stockSplit',
  SUBDISTRICT: '/admin/subDistrict',
  SYSTEM_CONFIG: '/system/config',
  TAG: '/admin/tag',
  TAG_POOL: '/admin/tagPool',
  TAG_LIST: '/admin/tagList',
  TAG_LIST_UPLOAD: '/admin/tagListUpload',
  TOKOPEDIA_USER: '/admin/tokopediaUser',
  TOPUP: '/admin/topup',
  TOPUP_AND_CASHOUT: '/admin/topupAndCashout',
  TRADING_COMPETITION_REPORT: '/admin/tradingCompetitionReport',
  TRANSACTIONS: '/admin/transactions',
  USD_CASHOUTS: '/admin/usdCashOuts',
  USD_PRICE: '/admin/usdPrice',
  USD_TOPUP: '/admin/usdTopUp',
  USD_TRANSACTIONS: '/admin/USDTransactions',
  USD_YIELD: '/admin/usdYield',
  USER: '/admin/user',
  USER_AML_RISK_RATING: '/admin/userAmlRiskRating',
  USER_BANK: '/admin/userBank',
  USER_BALANCE: '/admin/user/userBalance',
  USER_GSS_KYC_INFORMATION: '/admin/gssKyc',
  USER_IDSS_KYC_INFORMATION: '/admin/idssKyc',
  USER_INSURANCE: '/admin/userInsurance',
  USER_KYC_ATTRIBUTE: '/admin/userKycAttribute',
  USER_STATIC_VIRTUAL_ACCOUNT: '/admin/userVirtualAccount',
  USER_TAGGING_MAPPING: '/admin/userTagMapping',
  USER_WEALTH_TRUSTED_CONTACTS: '/admin/userWealthTrustedContact',
  VOUCHERS: '/admin/voucher',
  VOUCHERS_ACTIVATE: '/admin/voucher/activate',
  VOUCHERS_DEACTIVATE: '/admin/voucher/deactivate',
  VOUCHERS_SEND_EMAIL: '/admin/voucher/sendBulkEmail',
  VOUCHERS_STATE: '/admin/voucher/state',
  VOUCHERS_STATE_UPLOAD: '/admin/voucher/state/upload',
  VOUCHERS_STATUS: '/admin/voucher/purchaseOrder/:merchantId/:status',
  VOUCHERS_UPLOAD: '/admin/voucher/upload',
  KTP_OCR: '/admin/ktpOcr',
  USER_PEP_STATUS: '/admin/pepStatus',

  CRON_JOB_AIRFLOW: '/system/cron-job/airflow'
};
